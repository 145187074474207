@import './index.scss';


body {
    font-family: 'Roboto';
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow: hidden !important;
}

.all-dishes {
    width: 100%;
    height: 100vh;

    .items-dishes {
        width: 100%;
        height: calc(100vh - 0px);
        display: flex;

        @media screen and (max-width : 992px) {
            flex-direction: column;
        }

        .outer-dishes {
            // max-width: 150px;
            width: 100%;
            overflow: hidden;
            text-align: center;
            background-color: $gray;
            /* display: none; */
            display: flex;
            align-items: center;
            border-bottom: 2px solid $white;
            justify-content: space-between;


            @media screen and (max-width : 992px) {
                display: flex;
                max-width: 1140px;
                width: 100%;
            }

            // .active-1 {
            //     // border: 1px solid $orange;
            //     color: #000;
            //     border-radius: 5px;
            //     padding: 9px 25px;
            // }

            .dishes-items {
                // margin: 0px 5px;
                cursor: pointer;
                padding: 9px 25px;
                // display: block;
                display: inline-block;

                @media screen and (max-width:992px) {
                    padding: 2px 10px;
                }


                &:focus-within {
                    // border: 1px solid $orange;
                    color: #000;
                    padding: 9px 25px;
                    border-radius: 5px;

                    @media screen and (max-width:992px) {
                        padding: 1px 10px;
                    }
                }

                p {
                    font-size: 1.1rem;
                    line-height: 30px;
                    font-weight: 500;
                    margin-bottom: 0;
                    white-space: nowrap;
                    text-transform: uppercase;
                }

                .fa {
                    font-size: 35px;
                }

                a {
                    text-decoration: none;
                    color: #989898;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex-direction: column;

                    &:hover {
                        color: $black;
                    }

                    &.active-1 {
                        color: $black;
                    }
                }

            }

            .dishes-items-1 {
                // margin: 30px 15px;
                border-right: 2px solid $white;
                padding: 27px;

                img {
                    width: 75px;
                }
            }

            .dishes-user-items {
                overflow: auto;
                // height: 100%;
                // padding-bottom: 120px;
                background-color: #F4F4F4;
                height: 86px;
                display: flex;
                align-items: center;
                // justify-content: center;
                width: 100%;
                padding: 0 30px;


                img {
                    width: 40px;
                    aspect-ratio: 1/1;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    overflow: hidden;
                    object-fit: cover;
                    // border-radius: 50%;
                    // border: 2px solid $orange;
                    display: none;

                }

                &::-webkit-scrollbar {
                    width: 6px;

                }

                &::-webkit-scrollbar-thumb {
                    border-radius: 5px;
                    background-color: $gray;

                }

                @media screen and (max-width : 992px) {
                    display: flex;
                    max-width: 1140px;
                    width: 100%;
                    align-items: center;
                    justify-content: space-between;
                    padding-bottom: 0px;
                    padding-right: 30px;
                    // margin: 10px 0;

                }
            }

            svg {
                width: 100%;
                height: 50px;
            }

            .search-and-log-out {
                display: flex;
                align-items: center;
                position: relative;

                img {
                    width: 75px;
                    border-left: 2px solid #fff;
                    -webkit-border-left: 2px solid #fff;
                    height: 85px;
                    padding: 0px 23px;

                }

                .login-first-page {
                    color: #fff;
                    text-decoration: none;
                }

                .search-input {
                    position: absolute;
                    left: -198px;
                    top: 25px;

                    input {
                        width: 100%;

                        &:focus {
                            box-shadow: none;
                            border: 1px solid $black;
                        }

                    }

                }
            }
        }
    }

    .box-dishes {
        // padding: 0 20px 20px 20px;
        width: 100%;
        height: 100%;
        overflow: auto;
        position: relative;
        overflow: hidden;


        .menu-create {
            position: absolute;
            bottom: 40px;
            width: 100%;
            // margin: 20px auto;
        }

        .grid-img {
            white-space: nowrap;
            margin-right: 14px;
            background-color: $gray;
            padding: 5px 5px;
            display: flex;
            align-items: center;
            gap: 5px;
            text-align: center;
            display: none;

            a {
                width: 30px;

            }

            .size-images {
                width: 25px;
            }

            .five {
                width: 18px;
            }

            .search-input {
                position: relative;


                // [type="search"]::-webkit-search-cancel-button,
                // [type="search"]::-webkit-search-decoration {
                //     -webkit-appearance: none;
                //     appearance: none;
                // }

                .form-control {
                    border-radius: 0;
                    padding: 2px 20px 5px 7px;
                    height: 30px;


                    &:focus {
                        box-shadow: none;
                        border: 1px solid #000;
                    }

                }

                .fa {
                    position: absolute;
                    top: 9px;
                    right: 10px;
                    font-size: 12px;
                    cursor: pointer;
                }
            }


        }

        .outer-box-dishes {
            padding: 30px 30px 200px;
            overflow: auto;
            height: calc(100vh - 50px);
            background-color: #EFEFEF;

            @media screen and (max-width:720px) {
                padding-bottom: 180px;
            }

            &::-webkit-scrollbar {
                width: 6px;

            }

            &::-webkit-scrollbar-thumb {
                border-radius: 5px;
                background-color: $orange;

            }

            .item-card {
                box-shadow: 0;
                padding: 0;
                text-align: left;
                display: flex;
                // align-items: center;
                padding: 10px;
                border-radius: 5px;
                cursor: pointer;
                border: 1px solid #FFFFFF;
                height: 100%;

                &:hover {
                    background-color: #FFFFFF;
                    padding: 10px;
                    box-shadow: 0px 1px 4px #00000029;

                }

                &.out-of-stock {
                    position: relative;

                    &::after {
                        content: 'Out of Stock';
                        position: absolute;
                        left: 0;
                        top: 0;
                        width: 100%;
                        height: 100%;
                        background-color: rgba(0 0 0/65%);
                        font-size: 24px;
                        font-weight: 500;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        color: #fff;
                        border-radius: 5px;
                        pointer-events: none;
                    }

                    cursor: not-allowed !important;
                }

                .card-images {
                    .product-items-image {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        width: 80px;
                        aspect-ratio: 1/1;
                        // overflow: hidden;
                        border-radius: 5px;
                        // margin-right: 0.6rem;
                    }

                }

                .card-body {
                    padding: 5px 0 0px 10px;

                    .card-title {
                        font-size: 15px;
                        margin-bottom: 5px;
                        color: $black;

                    }

                    .card-text {
                        font-size: 1.4rem;
                        color: #555555;
                        margin-bottom: 10px;
                        text-transform: capitalize;
                        font-weight: 500;
                        margin-bottom: 13px;
                        text-align: left;
                        line-height: 29px;
                        word-wrap: break-word;
                        white-space: pre-wrap;

                    }

                    .sub_title {
                        font-size: 22px;
                        color: $orange;
                        font-weight: 500;
                        text-transform: capitalize;
                        margin-bottom: 0;

                    }
                }

                .grid-items-new {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background-color: $gray;

                    .fruits {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        margin: 0 17px;
                        width: 200px;
                        aspect-ratio: 1/1;
                        border-radius: 5px;
                        overflow: hidden;

                        .card-img-top-2 {
                            max-height: 100%;
                        }
                    }
                }
            }

            .card {
                background-color: $white;
                margin: 5px 0px;
                border-radius: 0;
                border: 0;
                padding: 7px;
                cursor: pointer;

                img {
                    width: 100%;
                    aspect-ratio: 2/1;
                    overflow: hidden;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    object-fit: cover;

                }

                &:hover {
                    background-color: $gray;
                    padding: 7px;
                }

                .card-body {
                    padding: 10px 5px;

                    .card-title {
                        font-size: 15px;
                        margin-bottom: 5px;
                        color: $black;

                    }

                    .card-text {
                        font-size: 1.4rem;
                        color: $black;
                        margin-bottom: 10px;
                        text-transform: capitalize;
                        font-weight: 600;
                    }

                    .sub_title {
                        font-size: 1.3rem;
                        color: $orange;
                        font-weight: 600;
                        text-transform: capitalize;

                    }
                }
            }
        }
    }
}

.not-available {
    position: relative;

    h3 {
        position: absolute;
        left: 45px;
        top: 150px;
        font-size: 30px;
        color: $black;
        font-weight: 600;
    }
}

.disabled-list {
    position: relative;

    &::after {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: #eeeeeed3;

    }
}

//  model css start


.model-select {
    border-radius: 0;
    border: 1px solid $orange;

    .modal-cover {
        padding: 0;

        .food-images-lists {
            height: 100%;
            width: 100%;

            img {
                height: 100%;
                width: 100%;
                object-fit: cover;
                margin-bottom: 0;

            }
        }

        .modal-outer-text {
            .girll-chicken {
                padding: 10px 20px;
                border-bottom: 1px solid $orange;

                .close-icon-button {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;


                    h3 {
                        margin-bottom: 0;
                        font-size: 20px;
                        font-weight: 700;
                        color: $black;

                    }
                }

                p {
                    margin-bottom: 0;
                    font-size: 20px;
                    font-weight: 700;
                    color: $black;
                }

                .count-2 {
                    display: flex;
                    margin-top: 20px;

                    .first-btn {
                        background-color: $white;
                        border: 1px solid $orange;
                        width: 40px;
                        height: 40px;
                        border-radius: 5px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        font-size: 30px;
                        margin-bottom: 0;
                    }

                    .last-btn {
                        @extend .first-btn
                    }

                    .center-btn {
                        background-color: $white;
                        border: 0;
                        border-radius: 0;
                        margin: 0 30px;
                        font-size: 20px;
                    }
                }
            }



            .size-select-food {
                padding: 0px 20px;
                border-bottom: 1px solid $orange;

                h3 {
                    font-size: 16px;
                    color: $black;
                    margin-bottom: 0;
                    font-weight: 600;
                    margin: 10px 0;
                }



                .button-radio {

                    .radioBtn {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        border-radius: 5px;

                        .radio-input {
                            display: none;
                        }

                        .radio__label {
                            font-size: 15px;
                            font-weight: 600;
                            padding: 5px 20px;
                            // margin-bottom: 10px;
                            text-align: center;
                            margin: 0 2px 4px;

                            h5 {
                                margin-bottom: 0;
                                font-size: 14px;
                                white-space: nowrap;
                                color: $black;
                            }

                            p {
                                margin-bottom: 0;
                                font-size: 15px;
                                color: $black;

                            }
                        }

                        .active {
                            border: 1px solid $orange;
                            border-radius: 5px;
                            background-color: #ba906d65;
                            padding: 5px 19px;
                            margin: 0 2px 3px;
                        }


                        .radio-input:checked+.radio__label {
                            border: 1px solid $orange;
                            border-radius: 5px;
                            background-color: #ba906d65;
                            padding: 5px 19px;
                            margin: 0 2px 3px;
                        }
                    }
                }
            }

            .complete-btn {
                padding: 20px;

                .cancel-button {

                    background-color: $black;
                    border: 0;
                    border-radius: 0;
                    padding: 13px 30px;
                    color: $white;
                    font-size: 16px;
                    margin-right: 20px;
                    font-weight: 600;

                    &:hover {
                        background-color: $orange;

                        color: #000;
                    }
                }

                .add-to-cart {
                    padding: 13px 65px;
                    border: 0;
                    background-color: $orange;
                    color: $black;
                    font-size: 15px;
                    font-weight: 600;

                    img {
                        width: 20px;
                    }

                    &:hover {
                        color: $white;
                        background-color: $black;

                    }
                }

            }

        }
    }


}

.size-select-food {
    padding: 10px 0px;
    // border-bottom: 1px solid $orange;

    .add-on-card {
        font-size: 1.3rem;
        color: $black;
        margin-bottom: 0;
        font-weight: 500;
        margin: 5px 0;
    }

    .coming-soon {
        font-size: 16px;
        color: $black;
        margin-bottom: 0;
    }

    .button-radio {

        .radioBtn {
            display: flex;
            align-items: center;
            justify-content: space-between;
            border-radius: 5px;

            .radio-input {
                display: none;
            }

            .radio__label {
                font-size: 15px;
                font-weight: 500;
                padding: 5px 20px;
                // margin-bottom: 10px;
                text-align: center;
                margin: 0 2px 4px;

                h5 {
                    margin-bottom: 0;
                    font-size: 14px;
                    white-space: nowrap;
                    color: $black;
                }

                p {
                    margin-bottom: 0;
                    font-size: 15px;
                    color: $black;

                }
            }

            .active {
                // border: 1px solid $orange;
                // border-radius: 5px;
                background-color: $orange;
                padding: 5px 19px;
                margin: 0 2px 3px;

            }


            .radio-input:checked+.radio__label {
                // border: 1px solid $orange;
                border-radius: 5px;
                background-color: $orange;
                padding: 5px 19px;
                margin: 0 2px 3px;
            }
        }
    }
}

.add-items {
    font-size: 1.3rem;
    color: $black;
    margin-bottom: 0;
    font-weight: 500;
    margin: 5px 0;
    cursor: pointer;
}

//  model css end

// Right bar css start

.right-check-bill {
    max-width: 370px;
    width: 100%;
    background-color: $white;
    position: relative;

    @media screen and (max-width :992px) {
        max-width: 1140px;
        width: 100%;
        display: none;
    }

    .order-table {
        .table_number {
            display: flex;
            align-items: center;
            justify-content: space-between;
            // padding: 15px 20px;

            .food-table {
                display: flex;
                align-items: center;

                img {
                    width: 35px;
                    margin-right: 10px;
                }

                p {
                    margin-bottom: 0;
                    font-size: 16px;
                    font-weight: 600;
                    color: $black;
                }

            }

            .table-order__number_list {
                font-size: 16px;
                color: $black;
                font-weight: 600;
                margin-bottom: 0;

                span {
                    padding-left: 5px;
                    font-weight: 600;
                    color: $orange;
                    font-size: 20px;
                }
            }

            .table__number__order {
                font-size: 20px;
                color: #BA906D;
                font-weight: 500;
                padding: 31px 30px;
                margin-bottom: 0;
                text-transform: uppercase;
            }
        }

        .food-order-items {
            .food-order-list {
                display: flex;
                justify-content: center;
                border-bottom: 1px solid $orange;
                background-color: rgb(223, 220, 220);
                padding: 5px 0;

                .nav-tabs {
                    border-bottom: 0;

                    .nav-link {
                        color: $black;
                        padding: 5px 25px;
                        border: 0;
                    }

                    .active {
                        background-color: transparent;
                        background-color: $orange;
                        border: 0;
                        border-radius: 4px;

                    }
                }
            }

            .tab-content {

                .tab-content-list {
                    height: calc(100vh - 370px);
                    overflow: auto;
                    // border-top: 1px solid $orange;
                    padding-bottom: 10px;

                    &::-webkit-scrollbar {
                        width: 6px;

                    }

                    &::-webkit-scrollbar-thumb {
                        border-radius: 5px;
                        background-color: $orange;

                    }
                }

                .tab-content-list-2 {
                    @extend .tab-content-list;
                    height: calc(100vh - 370px);
                }


                .table-booking {
                    border-bottom: 1px solid #707070;
                    padding: 15px 0px;
                    text-align: center;
                    margin: 0 30px;

                    .button-order {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        margin-top: 5px;

                        .food-select {
                            display: flex;
                            margin-left: 0;

                            .first-button {
                                background-color: $white;
                                border: 1px solid $orange;
                                width: 30px;
                                height: 30px;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                font-size: 25px;
                                margin-bottom: 0;
                            }

                            .center-button {
                                // background-color: $white;
                                // margin: 0 10px;
                                font-size: 20px;
                                // height: 30px;
                                // width: 50px;
                                // border: 1px solid $orange;
                                border: 0;
                                background-color: transparent;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                color: #919191;
                                padding: 0;
                            }
                        }

                        img {
                            background-color: $orange;
                            width: 30px;
                            padding: 5px;
                            cursor: pointer;
                        }

                        .add-on {
                            font-size: 15px;
                            color: $black;
                            font-weight: 600;
                            text-decoration: underline;
                            padding-top: 13px;
                            cursor: pointer;
                        }
                    }
                }

            }
        }


    }
}

.quantity {
    // padding-left: 20px;
    font-size: 1.2rem;
    color: $black;
}

.food-select {
    display: flex;
    margin-left: 20px;



    .first-button {
        background-color: #EFEFEF;
        border: 1px solid $black;
        width: 3rem;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 25px;
        margin-bottom: 0;
        color: $black;
    }

    .center-count-btn {
        border: 1px solid $black !important;
        width: 6rem;
        height: 40px;
        background-color: #EFEFEF !important;
        color: $black !important;
    }

    .center-button {
        // background-color: $white;
        // margin: 0 10px;
        font-size: 20px;
        // height: 30px;
        // width: 50px;
        // border: 1px solid $orange;
        border: 0;
        background-color: transparent;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #919191;
        padding: 0;
    }
}

.card-style-booking {
    border: 0;
    width: 100%;
    display: flex;
    cursor: pointer;
    align-items: center;
    justify-content: space-between;


    .order-booking {
        .product-image {
            display: flex;
            justify-content: center;
            width: 60px;
            height: 60px;
            object-fit: cover;
            overflow: hidden;
            margin-right: 20px;
            display: none
        }

        .product-food {
            @extend .product-image;
        }

    }

    .order-confirm {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: space-between;
    }

    // .delete-items {
    //     background-color: $orange;
    //     padding: 2px;
    // }
}

.order-confirm {
    text-align: left;

    .bill-heading-confirm {
        font-size: 1rem;
        color: $black;
        margin-bottom: 0px;
        font-weight: 500;
        text-transform: capitalize;
    }

    .sub-heading {
        font-size: 1.2rem;
        color: $orange;
        font-weight: 500;
        margin-bottom: 0;

        del {
            margin-right: 10px;
            font-size: 16px;
            color: gray;
        }
    }

    .list-customer-items {
        text-transform: capitalize;
        display: none;

        .label {
            font-size: 14px;
            color: rgb(116, 110, 110);
            font-weight: 600;
            padding-right: 15px;
            display: inline-block;
            width: 50px;
        }

        .size {
            font-size: 14px;
            color: rgb(26, 21, 21);

        }
    }

}

.subtotal {
    // margin-top: 10px;
    padding: 10px 30px 0;
    border-top: 2px solid #EFEFEF;

    p {
        margin-bottom: 0;
        font-size: 1.2rem;
        color: $black;
        font-family: 'Roboto';
        font-weight: 600;
        text-transform: capitalize;
    }

    h3 {
        font-size: 1.1rem;
        color: $orange;
        font-family: 'Roboto';
        font-weight: 600;
        margin-bottom: 0;
    }


    .taxes {
        display: flex;
        justify-content: space-between;
        align-content: center;

        .texes__title {
            // margin-bottom: 8px;
            font-size: 1.2rem;
            color: $black;
            font-weight: 600;
            // line-height: 40px;
            text-transform: capitalize;
            -webkit-font-smoothing: auto;
            font-family: 'Roboto';
            font-weight: 600;

        }

        .texes__heading {
            font-size: 1.2rem;
            color: $orange;
            font-weight: 500;
            margin-bottom: 0;
            -webkit-font-smoothing: auto;
        }
    }

    .pye-bile {
        @extend .taxes;

    }

    .discount-costumer {
        @extend .taxes;


    }

    .discount {
        margin-bottom: 0;
        color: $black;
        font-size: 16px;
        border-bottom: 1px solid $orange;
    }

    .customer-pye-bill {
        @extend .taxes;
        margin-top: 10px;
        padding-top: 10px;
        border-top: 1px solid #707070;

        .texes__title {
            font-size: 1.5rem;
        }

        .all-total {
            font-size: 1.4rem;
            font-family: 'Roboto';
            font-weight: 600;
        }
    }
}

.delivery-dine {
    padding: 27px 12px;
    font-size: 14px;
    text-align: center;
}

.btn-group {
    width: 100%;
    padding: 20px 30px;

    // position: absolute;
    // bottom: 0px;
    .continue-btn-order {
        border: 0 !important;
        // margin: 0 10px;
        background-color: $black !important;
        color: $orange !important;
        padding: 10px 20px !important;
        width: 70% !important;
        border-radius: 5px;

        p {
            font-size: 1.4rem !important;
            font-weight: 500 !important;
        }
    }

    .close-btn {
        background-color: $white !important;
        border: 0 !important;
        display: inline;
        width: 53px !important;
        margin-left: auto;

        &:hover {
            background-color: #fff !important;
            color: #000 !important;
        }

        .close-button {
            width: 40px;
        }

        .new-order-create {
            white-space: nowrap;
            transform: translateX(-10px);
        }
    }

    .verification {
        border: 1px solid #FFFFFF;
        width: 100%;
        text-align: center;
        color: #BA906D;
        background-color: #EAE3DE;

        .back-icon {
            color: #000;
        }

        .delivery-dine {
            text-transform: uppercase;
        }

        p {
            font-size: 14px;

            margin-bottom: 0;
            font-weight: 500;
        }

        &:hover {
            background-color: $orange;
            color: #fff;
        }
    }
}

// Right bar css end

// kitchen-order css start
.kitchen-order {
    width: 100%;
    height: 100vh;

    .kitchen-items {

        .kitchen-menu-list {
            display: flex;
            align-items: center;
            justify-content: space-between;
            background-color: #acacac5c;
            padding: 10px 20px;

            @media screen and (max-width:799px) {
                flex-wrap: wrap;
                gap: 10px;
                justify-content: center;
            }

            .kitchen-logo {
                width: 100px;
            }

            .kitchen-heading {
                font-size: 1.5rem;
                color: $black;
                font-weight: 600;
                margin-bottom: 0;
                padding-left: 20px;
                display: none;

                @media screen and (max-width :768px) {
                    font-size: 17px;
                }
            }

            .kitchen-link {


                img {
                    width: 30px;
                    margin: 0 10px;

                    @media screen and (max-width :768px) {
                        margin: 0 5px;
                        width: 25px;
                    }
                }
            }

            .kitchen-menu {
                display: flex;
                margin-bottom: 0;
                padding: 0;
                list-style: none;
                // margin-left: 210px;
                white-space: nowrap;
                // margin-left: auto;
                // margin-right: 25px;

                @media screen and (max-width : 992px) {
                    margin-left: 0;
                }

                li {
                    padding: 0 10px;

                    a {
                        text-decoration: none;
                        font-size: 1.3rem;
                        color: $black;
                        font-weight: 500;

                        &:hover {
                            border-bottom: 2px solid $orange;
                        }
                    }

                    .active {
                        border-bottom: 2px solid $orange;
                    }
                }
            }
        }

        .kitchen-items-outer {
            padding: 10px;
            overflow: auto;
            width: 100%;
            height: calc(100vh - 75px);

            @media screen and (max-width:558px) {
                height: calc(100vh - 155px);
            }

            &::-webkit-scrollbar {
                width: 6px;

            }

            &::-webkit-scrollbar-thumb {
                border-radius: 5px;
                background-color: $orange;

            }

            .kitchen-table-list {
                width: 100%;
                display: flex;
                margin-top: 0px;
                border: 1px solid gray;

                .table-order-number {
                    width: 30%;
                    text-align: right;
                    border-right: 1px solid gray;
                    padding: 20px;

                    h2 {
                        font-size: 25px;
                        color: $black;
                        font-weight: 600;
                    }

                    p {
                        font-size: 15px;
                        color: $black;
                        margin-bottom: 0;
                        margin: 5px 0;
                    }

                    span {
                        background-color: $orange;
                        font-size: 15px;
                        color: $black;
                        padding: 5px;
                        margin: 5px 0;
                    }
                }

                .order-table-completed {
                    width: 70%;
                    padding: 20px;

                    h4 {
                        font-size: 20px;
                        color: $black;
                    }

                    .customer-dealer {
                        .outer-kitchen {
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            padding-top: 10px;

                            h3 {
                                font-size: 16px;
                                color: $black;
                                margin-bottom: 0;
                                font-weight: 600;
                                text-transform: capitalize;
                            }

                            h4 {
                                @extend h3;
                            }
                        }



                        span {
                            font-size: 14px;
                            color: $black;
                            margin: 5px 0;
                            display: block;
                            text-transform: capitalize;
                        }
                    }


                    .disable-card {

                        &:hover {
                            background-color: $gray;
                        }

                        .dis {
                            position: relative;

                            .cover {
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                position: relative;

                                &::after {
                                    content: '';
                                    position: absolute;
                                    left: 0;
                                    top: 0;
                                    background-color: $gray;
                                    width: 100%;
                                    height: 100%;
                                }

                                h4 {
                                    position: absolute;
                                    z-index: 999;
                                }

                            }

                            .cover-images {
                                position: relative;

                                &::before {
                                    content: '';
                                    position: absolute;
                                    left: 0;
                                    top: 0;
                                    background-color: rgba(129, 127, 127, 0.534);
                                    height: 100%;
                                    width: 100%;
                                }

                                h4 {
                                    position: absolute;
                                    top: 50%;
                                    left: 50%;
                                    transform: translate(-50%, -50%);
                                    z-index: 999;
                                    color: $white;
                                }
                            }

                            h4 {

                                font-size: 25px;
                                color: $black;
                                font-weight: 600;

                                @media screen and (max-width:992px) {
                                    font-size: 18px;
                                }
                            }
                        }

                        .dis-line {
                            opacity: 0.5;

                            .outer-kitchen {
                                display: flex;
                                align-items: center;
                                justify-content: space-between;
                                padding-top: 10px;

                                h3 {
                                    font-size: 16px;
                                    color: $black;
                                    margin-bottom: 0;
                                    font-weight: 600;
                                    text-transform: capitalize;
                                }

                                h4 {
                                    @extend h3;
                                }
                            }

                            span {
                                font-size: 14px;
                                color: $black;
                                margin: 5px 0;
                                display: block;
                                text-transform: capitalize;
                            }
                        }
                    }

                }

            }

            .available-table {
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 0 20px;

                @media screen and (max-width :568px) {
                    flex-direction: column;
                    align-items: start;
                }

                .available {
                    h3 {
                        font-size: 24px;
                        color: $black;

                        @media screen and (max-width :768px) {
                            font-size: 16px;
                        }
                    }

                    .all-food-timing {
                        display: flex;
                        align-items: center;

                        img {
                            width: 13px;
                            margin-right: 5px;
                        }

                        span {
                            font-size: 15px;
                            color: $black;
                            font-weight: 500;
                            padding-right: 15px;

                            @media screen and (max-width :678px) {
                                white-space: nowrap;
                            }
                        }
                    }
                }

                .input-group {
                    width: 250px;


                    @media screen and (max-width :678px) {
                        width: 100%;
                    }

                    .form-control {
                        &:focus {
                            border: 1px solid $orange;
                            box-shadow: none;
                        }
                    }

                    span {
                        img {
                            width: 20px;
                        }
                    }
                }
            }

            .card-arrow {
                border: 1px solid $orange;
                margin-top: 10px;
                cursor: pointer;

                .card-number {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    border-bottom: 1px solid $orange;
                    padding: 10px;

                    h4 {
                        font-size: 14px;
                        color: $black;
                        font-weight: 600;
                    }

                    h2 {
                        font-size: 27px;
                        color: $black;
                        font-weight: 700;
                    }

                    img {
                        width: 35px;
                    }
                }

                .table-time {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    padding: 5px 10px;
                    border-bottom: 1px solid $orange;


                    p {
                        margin-bottom: 0;
                        font-size: 14px;
                        color: $black;
                        font-weight: 600;
                    }

                    img {
                        width: 10px;
                    }
                }
            }

            .stock-card {
                margin: 3px 0;
                padding: 6px;
                border: 0;
                border-radius: 0;

                &:hover {
                    background-color: $gray;
                    padding: 6px;
                }

                img {
                    border-radius: 0;
                }

                .stock-body {
                    padding: 10px 10px;

                    .stock-heading {
                        font-size: 20px;
                        color: $black;
                        font-weight: 500;
                        margin-bottom: 0;
                        text-transform: capitalize;
                    }

                    .stock-sub-title {
                        font-size: 16px;
                        color: $black;
                        margin-bottom: 0;
                        text-transform: capitalize;
                    }

                    .stock-update {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        margin: 10px 0;

                        p {
                            margin-bottom: 0;
                            font-size: 16px;
                            color: $black;
                            text-transform: capitalize;
                        }

                        input {
                            max-width: 60px;
                            width: 100%;
                            border-color: $orange;
                            padding: 5px 5px;

                            &:focus {
                                color: #212529;
                                background-color: #fff;
                                border-color: $orange;
                                outline: 0;
                                box-shadow: 0 0 0 0.25rem rgb(62 64 68 / 25%);
                            }
                        }
                    }

                    .availability {
                        display: flex;
                        margin: 10px 0;
                        align-items: center;
                        justify-content: space-between;


                        .form-check-input:checked {
                            background-color: $orange;
                            border-color: $orange;
                        }

                        .form-check-input:focus {
                            border-color: $orange;
                            outline: 0;
                            box-shadow: 0 0 0 0.25rem rgb(102 104 106 / 25%);
                            background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
                        }
                    }

                    .outer-button {
                        .choose-btn {
                            width: 17px;
                            margin-right: 10px;
                        }
                    }
                }
            }
        }

        .counter-checkout {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .bill-customer-box {
                border: 1px solid $orange;
                cursor: pointer;
                position: relative;

                span {
                    position: absolute;
                    top: 5px;
                    right: 5px;
                    height: 10px;
                    width: 10px;
                    background-color: $orange;
                    border-radius: 50px;

                }

                .counter-outer-table {
                    text-align: center;
                    padding: 10px 0;

                    h3 {
                        font-size: 15px;
                        font-weight: 600;
                        margin-bottom: 0;

                        color: $black;
                    }

                    h1 {
                        font-size: 50px;
                        margin-bottom: 0;
                        font-weight: 600;
                        color: $black;
                    }

                    p {
                        font-size: 15px;
                        margin-bottom: 0;
                        color: $black;
                    }
                }

                .user-table {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    border-top: 1px solid $orange;


                    .user {
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        padding: 5px 40px;

                        img {
                            width: 20px;

                        }

                        p {
                            margin-bottom: 0;
                            font-size: 14px;
                            color: $black;

                        }
                    }

                    .time-counter {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        padding: 5px 0;
                        border-left: 1px solid $orange;

                        img {
                            width: 20px;

                        }

                        p {
                            margin-bottom: 0;
                        }
                    }
                }
            }
        }

    }
}

.kitchen-date-timing {
    display: flex;
    align-items: center;

    .search-input-time {
        position: relative;
        display: flex;
        gap: 10px;

        // [type="search"]::-webkit-search-cancel-button,
        // [type="search"]::-webkit-search-decoration {
        //     -webkit-appearance: none;
        //     appearance: none;
        // }

        .form-control {
            border-radius: 0;
            // padding: 2px 20px 2px 10px;

            &:focus {
                box-shadow: none;
                border: 1px solid #000;
            }

        }

        .fa {
            position: absolute;
            top: 12px;
            right: 10px;
            font-size: 12px;
            cursor: pointer;
        }
    }
}



// Kitchen model css start
.kitchen-title {
    border: 1px solid $orange;
    border-radius: 0;
    background-color: $gray;

    .kitchen-modal-header {
        border-bottom: 1px solid $orange;

        img {
            width: 30px;


        }

        h5 {
            font-size: 20px;
            padding: 0 10px;
            font-weight: 600;
            color: $black;
        }

        span {
            font-size: 14px;
            font-weight: 600;
        }
    }

    .kitchen-modal-body {
        border-bottom: 1px solid $orange;

        .input-group {
            margin: 8px 0;

            span {
                font-size: 14px;
                color: $white;
                border: 1px solid $orange;
                background-color: $orange;
                border-radius: 0;
            }

            input {
                border: 1px solid $orange;
                border-radius: 0;

                &:focus {
                    box-shadow: none;
                }
            }
        }
    }
}

// Kitchen model css end


// kitchen-order css end

// Header css custom start
.menu-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 5px;
    padding: 0 13px;
    position: sticky;
    top: 0;
    z-index: 1;

    @media screen and (max-width: 720px) {
        flex-wrap: wrap;
    }

}

header {
    display: inline-flex;
    // width: 100%;
    align-items: center;
    // justify-content: space-between;
    justify-content: center;
    padding: 10px 20px 0;
    position: sticky;
    top: 0;

    width: 100%;
    z-index: 1;

    @media screen and (max-width:720px) {
        flex-wrap: wrap;
        gap: 10px;
    }

    ul {
        list-style: none;
        padding: 0;
        margin-bottom: 0;
        background-color: #F4F4F4;
        box-shadow: 0px 4px 60px #00000057;
        padding: 15px 0;
        border-radius: 4px;

        li {
            display: inline-block;
            padding: 0px 10px;
            text-transform: uppercase;

            .active-menu {
                background-color: #E5E5E5;
                border-radius: 5px;
                padding: 5px;

            }

            a {
                text-decoration: none;
                color: #A0A0A0;
                font-size: 1.0rem;
                font-weight: 500;

                &:hover {
                    // border-bottom: 2px solid $orange;
                    color: $black;
                }

            }

            .active {
                // border-bottom: 2px solid $orange;
                background-color: #E5E5E5;
                border-radius: 5px;
                padding: 10px 20px;
                color: #000;
            }
        }
    }
}


// Header css custom  end

// history Order css start .
.clear-btn {

    color: $orange;
    cursor: pointer;

    &:hover {
        color: $orange;
    }
}

.customer-dishes {
    // width: 100%;
    // height: calc(100vh - 120px);
    position: relative;

    .customer-food-select {
        display: flex;

        .table-dishes {
            // height: calc(100vh - 78px);
            // margin-top: 10px;
            // width: calc(100% - 350px);
            // flex: 1;
            width: 100%;
            overflow: auto;
            // padding: 0 30px;

            &::-webkit-scrollbar {
                width: 6px;

            }

            &::-webkit-scrollbar-thumb {
                border-radius: 5px;
                background-color: $orange;

            }

            @media screen and (max-width : 758px) {
                height: calc(100vh - 85px);
            }

            .input-date-picker {
                // display: flex;
                // align-items: center;
                // justify-content: flex-end;
                // gap: 10px;
                // padding-right: 30px;

                label {
                    font-size: 1rem;
                    color: $black;
                    font-weight: 500;
                    // text-align: center;
                    // padding: 0 10px;
                }

                .date-required {
                    margin-bottom: 0;
                    white-space: nowrap;
                    color: $black;
                    font-size: 1rem;
                    font-weight: 500;
                }

                .date-picker {
                    // display: flex;
                    // align-items: center;
                    // gap: 10px;


                    input {
                        border-radius: 0;
                        padding: 0.5rem 0.75rem;


                        &:focus {
                            box-shadow: none;
                            outline: 0;
                            border: 1px solid #ced4da;
                        }

                        p &:focus {
                            box-shadow: none;
                            border: 1px solid $black;
                        }
                    }

                    .date-required {
                        padding-left: 10px;
                    }
                }
            }

            .table-responsive {
                width: 100%;
                text-align: center;
                // margin-top: 10px;
                height: calc(100vh - 140px);
                position: relative;

                &::-webkit-scrollbar {
                    width: 6px;

                }

                &::-webkit-scrollbar-thumb {
                    border-radius: 5px;
                    background-color: $orange;

                }

                .thead-default {
                    border-bottom: 1px solid $black;
                    white-space: nowrap;
                    font-weight: 600;
                    background-color: $orange;
                    color: $white;
                }

                .tbody-default {
                    white-space: nowrap;
                    font-size: 17px;
                    color: $black;

                    a {

                        color: $black;
                    }

                    .status {
                        display: inline;
                        padding: 3px 13px;
                        border-radius: 5px;
                        font-size: 15px;
                    }

                    .progress {
                        color: #eca711;
                        background-color: #eee3ca94;
                        padding: 3px 10px;

                    }

                    .complete {

                        color: rgb(15, 124, 15);
                        background-color: #97f08565;
                    }

                    .cancelled {
                        background-color: #f1bfca69;
                        color: #f73461;
                    }

                }
            }
        }

        .price-times-select {
            position: absolute;
            right: 0;
            top: 0;
            width: 350px;
            background-color: $gray;
            height: calc(100vh - 63px);

            .select-heading {
                font-size: 16px;
                color: $black;
                border-bottom: 1px solid $black;
                padding: 15px 20px;

                span {
                    font-weight: 600;
                }

                a {
                    border: 1px solid rgb(192, 189, 189);
                    padding: 10px;
                    margin-top: -12px;

                    img {
                        width: 20px;
                    }
                }
            }

            .food-scroll {
                height: calc(100vh - 290px);
                overflow: auto;
                border-bottom: 1px solid $black;

                &::-webkit-scrollbar {
                    width: 6px;

                }

                &::-webkit-scrollbar-thumb {
                    border-radius: 5px;
                    background-color: $orange;

                }
            }

            .price-card {
                display: flex;
                align-items: flex-start;
                padding: 10px 20px;
                border-bottom: 1px solid rgba(128, 128, 128, 0.164);
            }
        }
    }
}

.select-input {
    // width: 110px;
    border-radius: 0 !important;
    border: 1px solid #ced4da;
    padding: 0.5rem 5px !important;

    &:focus {
        box-shadow: none;
        outline: 0;
        border: 1px solid #ced4da;
    }

    &.width {
        width: 60px;
    }

}

// history Order css end

// log-in form css start


// log-in form css end 

.place-title {
    font-size: 20px;
    color: $orange;
    font-weight: 500;
    margin-bottom: 0;
    padding: 31px 30px;
    border-bottom: 2px solid #EFEFEF;
    margin-bottom: 10px;
    text-transform: uppercase;
}

.items-of-order-type {
    font-size: 16px;
    color: #000;
    padding: 0 30px;
    margin-bottom: 0;
    padding-top: 28px;
}

.order-number-table-user {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 2px solid #EFEFEF;
    padding: 31px 30px;

    h6 {
        margin-bottom: 0;
        font-size: 20px;
        font-weight: 500;
        color: $orange;
        text-transform: uppercase;
    }
}

.table {
    width: 100%;
    overflow: scroll;
    margin: 0;

    th {
        font-size: 16px;
        line-height: 20px;

    }

    td {
        font-size: 0.9rem;
        color: $black;
        font-weight: 600;
        vertical-align: middle;
        padding: 3px 10px 3px 0;
    }
}

.radio-changes {
    border: 2px solid #fff !important;

    .label-title {
        border-right: 2px solid #fff !important;
    }


}

.radio-changes1-2 {
    border: 2px solid #fff !important;
    border-top: 0px solid transparent !important;

    .label-title {
        border-right: 2px solid #fff !important;

    }
}

.card-payment {
    padding: 11px;
    text-transform: uppercase;

}

.refund-items {
    margin-bottom: 0;
    padding: 0;
    text-align: right;
    // margin: 5px 0;
    // padding-right: 20px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 15px;
    width: 130px;
    margin: 0 auto;
}

.refund-btn {
    text-decoration: none;
    background-color: #EAE3DE;
    padding: 10px 13px;
    color: #BA906D !important;
    margin: 0 1px;
    cursor: pointer;

    &:hover {
        background-color: #BA906D;
        color: #fff !important;
    }

}

.radioBtn {
    display: flex;
    align-items: center;
    justify-content: center;
    justify-content: space-between;
    border-radius: 0px;
    background-color: $white;
    border: 2px solid #ffff;
    flex-wrap: wrap;
    // padding: 3px;
    // padding: 0 30px;
    margin: 0 30px 16px;

    .radio-input {
        display: none;
    }

    .button-dollar-tip {
        font-size: 15px;
        margin-bottom: 0;
        font-weight: 600;
    }

    .label-asap {
        border-right: 2px solid #ffff;
        width: 50% !important;
    }


    .radio__label {
        font-size: 15px;
        font-weight: 500;
        padding: 11px 25px;
        color: #BA906D;
        background-color: #EAE3DE;
        // width: 50%;
        width: calc(100% / 3);
        text-align: center;
        border-bottom: 2px solid #fff;
        text-transform: uppercase;

        p {
            margin-bottom: 0;
        }

    }

    .radio-input:checked+.radio__label {
        background-color: $orange !important;
        // border-radius: 5px;
        color: $white;

    }
}

.deliver-time {

    .schedule {
        display: flex;
        padding: 0 30px;
        gap: 0px;

        .select-schedule {
            flex: 1;
            -webkit-flex: 1;
            -ms-flex: 1;

            label {
                font-size: 16px;
                color: #696969;
                line-height: 20px;
                text-align: center;
                width: 100%;
            }

            .input-left {
                border-left: 2px solid transparent !important;
                padding: 8px 6px !important;
                -webkit-padding: 7px 6px !important;
            }

            .input-list {
                border: 2px solid #EAE3DE;
                border-radius: 0px;
                color: #151515;
                padding: 8px 6px;
                -webkit-padding: 8px 6px;
                -moz-padding: 8px 6px;
                -webkit-text-algin: center;
                width: 100%;

                &:focus {
                    box-shadow: none;
                    border: 2px solid #EAE3DE;
                }
            }


        }
    }

    .dine-in {
        display: flex;
        // align-items: center;
        margin: 20px 0 0;


        h3 {
            font-size: 16px;
            color: $black;
            margin-bottom: 0;
            padding: 8px 30px;
        }

        .form-select {
            width: 137px;

            margin-left: 70px;

            &:focus {
                box-shadow: none;
                border: 1px solid $black;
            }
        }
    }

    .add-user-address {
        // text-align: flex-end;
        // padding-right: 30px;
        padding: 0 30px;

        a {
            padding-left: 30px;
            text-decoration: none;
            color: #000;
            background-color: #fff;
            padding: 10px;
            text-align: center;
            font-size: 16px;
            font-weight: 600;
            border: 2px solid #BA906D;
            display: block;
            text-transform: uppercase;
        }

        .add-icon {
            padding: 0 5px;
            color: #000;
        }
    }

    .text-area {
        padding: 20px 30px 0;

        .form-label {
            // border-bottom: 1px solid rgb(129, 129, 172);
            font-size: 18px;
            font-weight: 600;
        }
    }

    .address-user {
        padding: 0 30px;
        display: flex;
        justify-content: space-between;
        align-items: flex-end;

        p {
            font-size: 0.9rem;
            color: $black;
            margin-bottom: 0;

        }

        .edit-button-form {
            text-align: right;
        }

        a {
            display: inline-block;
            text-align: flex-end;
            text-decoration: none;
            background-color: #EAE3DE;
            padding: 11px 28px;
            color: #BA906D;
            border: 2px solid #FFFFFF;
        }
    }

}

.modal-content {

    .modal-header {
        .modal-title {
            font-size: 20px;
            color: $black;
        }
    }

    .modal-form {
        max-height: 400px;
        overflow: auto;

        .form-label {
            font-size: 1.1rem;
            color: $black;
            line-height: 20px;

        }

        .form-control {
            margin-bottom: 10px;
        }
    }
}


.modal-card {
    .modal-header {
        .modal-title {
            font-size: 1.3rem;

        }

        .modal-price {
            font-size: 1rem;
            color: $orange;
            margin-bottom: 0;
        }
    }

    .modal-button-footer {
        display: block;

        .first-box {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        .confirm {
            display: flex;
            gap: 10px;
            margin-top: 10px;
            align-items: center;
            justify-content: flex-end;

        }
    }
}

.box-add {
    width: 40px;
    aspect-ratio: 1/1;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid $gray;
}

.delivery-man {
    width: 25px;
}

.customer-order-place {
    padding: 20px;
    max-height: 580px;
    overflow-y: auto;
    overflow-x: hidden;

    .order-table-list-items {
        max-height: 350px;
        overflow: auto;
    }

    .review-order {
        border-right: 1px solid $black;
        height: 100%;

        .order-details {
            font-size: 1.3rem;
            margin-bottom: 5px;
            line-height: 30px;
            color: $black;
        }

        .dine-in-list {
            @extend .order-details;
            font-size: 1rem;
        }

        .table-no {
            @extend .order-details;
        }

        .customer-pye-bill {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding-right: 20px;
            font-weight: 600;
            padding: 10px 7px;
            border-top: 1px solid #000;

            .all-total {
                text-transform: uppercase;
                font-size: 1.3rem;
                margin-bottom: 0;


            }

            .payment {
                font-size: 20px;
                color: $orange;
                margin-bottom: 5px;

            }
        }

    }

    .comment-section {
        position: relative;
        height: 100%;

        .nots {
            font-size: 1.3rem;
            margin-bottom: 0;
            line-height: 30px;
            color: $black;
        }

        .form-control {
            margin-bottom: 20px;
            margin-top: 10px;
        }

        .please-btn {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            gap: 10px;
        }
    }
}

.input-select {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .check-out-input {
        .form-check-label {
            font-size: 16px;
        }

        .form-check-input {
            height: 20px;
            width: 20px;
            text-align: center;
            border-radius: 0;

            &:focus {
                box-shadow: none;
                border: 1px solid $black;
            }
        }
    }
}

.select-card {
    .select-items-all {
        .select-item-card {
            font-size: 1.3rem;
            margin-bottom: 5px;
            line-height: 30px;
            color: #000;
        }

        .lorem-card {
            font-size: 16px;
            color: rgb(167, 163, 163);
        }

        .card-button {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 10px;

            .card-btn {
                padding: 15px 50px;
                color: $white;
            }
        }

    }

    .select-modal-footer {
        display: block;
        text-align: center;

        .close-btn-select {
            display: flex;

            justify-content: flex-end;
        }
    }
}

.hand-right {
    background-color: $orange;
    padding: 10px 40px;
    text-decoration: none;
    color: $white;
    font-size: 15px;
    font-weight: 500;
    margin-right: 20px;

}

.filter {
    position: fixed;
    z-index: 9;
    background-color: rgb(0 0 0 / 70%);
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    display: none;
    align-items: center;
    justify-content: center;
    visibility: hidden;

    &.show {
        display: flex;
        visibility: visible;
    }

    .input-date-picker {
        background-color: $white;
        max-width: 450px;
        width: 100%;
        padding: 20px;

        .filter_order {
            font-size: 20px;
            margin-bottom: 0;
            border-bottom: 1px solid #000;
            font-weight: 400;
            font-size: 20px;
            color: #BA906D;
            font-weight: 500;
            text-align: center;
            margin-bottom: 0;
            text-transform: uppercase;
            padding-bottom: 15px;
        }
    }

    .date-picker {

        p {
            font-size: 20px;
            padding: 10px 0 !important;
        }

        .filter-date {
            display: flex;
            align-items: center;

            label {
                width: 150px;
                text-align: left;
                padding-left: 0 !important;

            }

            .form-control {
                margin: 10px 0;
            }
        }
    }
}

.mode {
    display: flex;
    align-items: center;

    label {
        width: 150px;
        text-align: left !important;
    }

    .select-input {
        margin: 10px 0;
    }
}

.clear-go {
    margin-top: 20px;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: flex-end;

    a {
        &:hover {
            color: #000;
            cursor: pointer;
        }
    }
}

.popup {
    margin-bottom: 0;
    // padding-right: 40px;
    font-size: 16px;
    border: 1px solid rgba(180, 179, 179, 0.658);
    padding: 8px 30px;
    display: block;
    cursor: pointer;
    font-weight: 400;
}

.no-record-found {
    margin-top: 10%;
    font-size: 20px;
    color: rgb(239, 232, 232);
}

.filter-order-list {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    color: $orange;
    font-size: 24px;
    margin: 0;
}