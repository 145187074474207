.btn-group .verification.active {
  background-color: #ba906d;
  color: #fff;
}
.verification small {
  font-size: 10px;
  margin: 0;
  padding: 0;
  color: #5d5a5a;
  display: grid;
}
.grid-img a {
  color: #000;
}
.grid-img a.active {
  color: #ba906d;
}
.right-check-bill .order-table .table_number {
  border-bottom: 2px solid #efefef;
}
.table_number-list {
  border-bottom: 0 solid transparent !important;
}
.autocomplete-results {
  list-style-type: none;
  padding: 0;
  margin: 0;
  position: absolute;
  top: 45px;
  right: 163px;
  overflow: auto;
}
.autocomplete-results li {
  padding: 8px;
  background-color: #f5f5f5;
  border-bottom: 1px solid #ddd;
  cursor: pointer;
  min-width: 180px;
  display: block;
}

.autocomplete-results li:last-child {
  border-bottom: none;
}

.autocomplete-results li:hover {
  background-color: #e0e0e0;
}
a.logout {
  border-left: 1px solid;
}

.pagination {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  padding: 0 40px;
}

.pagination ul {
  list-style: none;
  display: flex;
  margin: 0;
  padding: 0;
}

.pagination li {
  margin: 0 5px;
  background-color: #000;
}

.pagination a {
  display: block;
  padding: 8px 15px;
  /* border: 1px solid #ccc; */
  text-decoration: none;
  color: #fff;
}

.pagination a:hover {
  background-color: #000;
}

.pagination .active a {
  background-color: #4caf50;
  color: white;
  border-color: #4caf50;
}

.pagination .disabled a {
  color: #ccc;
  cursor: not-allowed;
  border-color: #ccc;
}

.pagination a.active {
  background-color: #ba906d;
  color: black;
  border-radius: 5px;
}
.show-2 {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 1rem;
  color: #000;
  font-weight: 500;
}
.pagination select {
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  padding: 5px;
  cursor: pointer;
}
.payment-msg {
  height: calc(100vh - 360px);
  display: flex;
  justify-content: center;
  align-items: center;
}
.uppercase {
  text-transform: uppercase;
}
.device-button {
  display: flex;
}
.device-button button {
  margin-right: 10px;
}
.weightValue {
  color: #919191;
  padding: 5px 0 5px 15px;
}
.search-box {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background: rgba(0 0 0/80%);
  left: 0;
  top: 0;
  z-index: 10;
  padding: 10vh 5vw;
}

.search-box .search-input {
  max-width: 50vw;
  margin: 10vh auto 0;
  display: flex;
  align-items: center;
  gap: 20px;
}

.search-box .search-input input {
  padding: 10px 20px;
  border-radius: 0;
  border: 0;
  font-size: 20px;
}

.search-box .search-input > a {
  color: white;
  text-decoration: none;
  font-size: 25px;
}

.search-box .autocomplete-results {
  position: relative;
  left: 0;
  top: 0;
  max-width: 50vw;
  margin: 10vh auto 0;
  display: flex;
  gap: 20px;
}

.search-box .autocomplete-results li {
  padding: 20px;
  border-radius: 3px;
  margin: 0;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: left;
}

.search-box .autocomplete-results li span {
  color: #ba906d;
}
.mtop0 {
  margin-top: 0 !important;
}

.action-buttons {
  padding: 2rem;
  flex-wrap: wrap;
  display: flex;
  flex-direction: row;
  gap: 20px;
}

.action-buttons button {
  flex: 1;
  padding: 20px;
  border: 1px solid #d2d2d2;
  text-transform: uppercase;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
.modal-content .close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
}

.btn-group .continue-btn-order-sm {
  border: 0 !important;
  background-color: #000 !important;
  color: #ba906d !important;
  padding: 4px 10px !important;
  width: 50% !important;
  border-radius: 5px;
}
.kitchen-date-timing .search-input-time {
  min-width: 250px;
}
.filter_order{
  position: relative;
}
.filter-close, .filter-close:hover{
  position: absolute;
    right: 5px;
    color: #ccc;
}