@font-face {
    font-family: 'Roboto';
    src: url('../../public/assets/fonts/Roboto-Regular.ttf');
}

@font-face {
    font-family: 'Roboto';
    src: url('../../public/assets/fonts/Roboto-Bold.ttf');
    font-weight: 800;
}

@font-face {
    font-family: 'Roboto';
    src: url('../../public/assets/fonts/Roboto-Medium.ttf');
    font-weight: 500;
}