// .form-login-submit {
//     background-image: linear-gradient(140deg, #BA906D, #E3C3A7);
//     position: relative;
//     // overflow: hidden;
//     width: 100%;
//     height: 100vh;
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     overflow: auto !important;
//     // z-index: ;

//     .images-f {
//         position: absolute;
//         left: -70px;
//         bottom: 00px;
//         width: 800px;


//     }

//     .logo-kitchen {
//         display: flex;
//         align-items: center;
//         justify-content: center;
//         height: 100%;
//         position: relative;
//         z-index: 3;


//         @media screen and (max-width :992px) {
//             transform: translateY(-50px);
//         }


//         img {
//             margin-bottom: 50px;
//             width: 250px;

//             @media screen and (max-width :992px) {
//                 width: 130px;
//                 margin-bottom: 0;
//             }
//         }

//     }

//     .login-dealer-heading {
//         position: relative;
//         z-index: 3;
//         height: calc(100vh - 50px);
//         display: flex;
//         justify-content: center;
//         flex-direction: column;
//         max-width: 550px;
//         width: 100%;
//         margin: auto;


//         @media screen and (max-width : 992px) {
//             margin-left: 0;
//             margin-right: 0;
//             height: auto;
//         }

//         form {
//             position: relative;
//             z-index: 3;

//             .form-label {
//                 font-size: 20px;
//                 color: #6F4929;
//                 font-weight: 500;
//                 line-height: 25px;
//                 margin-top: 10px;
//             }

//             .form-control {
//                 margin-bottom: 10px;
//                 border: 1px solid #FFFFFF81;
//                 border-radius: 4px;
//                 padding: 10px 10px;
//                 // color: #0000004D;
//                 background-color: #D8BCA3 !important;


//                 &:focus {
//                     box-shadow: none;
//                     background-color: #D8BCA3 !important;

//                 }
//             }

//             .password-forgot {
//                 text-align: flex-end;
//                 font-size: 16px;
//                 color: $white;
//                 line-height: 25px;
//                 cursor: pointer;
//                 margin-top: 10px;
//             }

//             .login-btn {
//                 margin-left: auto;
//                 margin-top: 2.5rem;
//                 padding: 10px 60px;
//                 color: #BA906D;
//                 border-radius: 4px;
//                 font-size: 1.3rem;
//             }
//         }




//         .heading-login {
//             font-size: 30px;
//             font-weight: 500;
//             color: #fff;
//             line-height: 20px;
//             margin-top: 20px;
//             transform: translateY(-80px);

//             @media screen and (max-width : 992px) {
//                 transform: translateY(0px);
//             }
//         }

//         .login-title {
//             font-size: 18px;
//             color: #FFFFFF80;
//             font-family: 'Roboto';
//             transform: translateY(-80px);

//             @media screen and (max-width : 992px) {
//                 transform: translateY(0px);
//             }
//         }


//     }

//     // .login-dealer {
//     //     margin-left: 90px;
//     //     height: 100vh;
//     //     display: flex;
//     //     justify-content: center;
//     //     flex-direction: column;
//     //     margin-right: 40px;


//     //     @media screen and (max-width : 992px) {
//     //         margin-left: 0;
//     //         margin-right: 0;
//     //     }
//     // }
// }

// .password-please-text {
//     margin-top: auto;
//     color: #0000005C;
//     font-size: 1rem;
//     position: relative;
//     z-index: 3;
//     max-width: 550px;
//     width: 100%;
//     margin: auto;
//     transform: translateY(-50px);

//     @media screen and (max-width: 992px) {
//         margin-top: 0;
//         padding-top: 30px;
//         transform: translateY(0px);
//     }
// }

// end css login page

.log-in {
    background-image: linear-gradient(140deg, #BA906D, #E3C3A7);
    flex: 1;
    height: 100vh;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    .file-form {
        max-width: 1140px;
        width: 100%;
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        z-index: 9;
        padding-left: 18px;

        @media screen and (max-width:992px) {
            flex-direction: column;
            padding: 0 20px;
        }

        .logo-image-dark {
            max-width: 350px;
            width: 100%;
            text-align: center;

            img {
                width: 250px;
                margin: 0px 0 54px;
            }

        }

        .details-user-login {
            flex: 1;
            padding-left: 10px;
            padding-top: 30px;


            @media screen and (max-width:99px) {
                padding-left: 0;
                padding-top: 0;
            }

            .form-heading {
                max-width: 540px;
                width: 100%;
                margin: 0 auto;
                transform: translateY(-85px);

                @media screen and (max-width :1050px) {
                    padding-left: 50px;
                }

                @media screen and (max-width :992px) {
                    transform: translateY(0px);
                    padding-left: 0;
                }

                .login-text {
                    font-size: 30px;
                    font-weight: 500;
                    color: #fff;
                    line-height: 20px;
                    margin: 0;
                    padding-bottom: 10px;
                }

                p {
                    margin: 0;
                    font-size: 18px;
                    color: hsla(0, 0%, 100%, .502);
                    font-weight: 600;
                }
            }
        }

        form {
            flex: 1;
            padding: 0 100px;
            display: flex;
            justify-content: center;
            flex-direction: column;
            padding-bottom: 10px;
            margin: 0 auto;

            @media screen and (max-width:992px) {
                padding: 0 0;
            }

            .label-name {
                color: #6f4929;
                font-size: 20px;
                font-weight: 500;
                line-height: 25px;
                margin: 10px 0;

            }

            .input-control {
                margin-bottom: 10px;
                width: 100%;
                padding: 12px 10px;
                border: transparent;
                box-shadow: none;
                background-color: #d8bca3 !important;
                border: 1px solid hsla(0, 0%, 100%, .506);
                border-radius: 5px;

                &:focus-visible {
                    box-sizing: none;
                    outline: none;

                }

                &::placeholder {
                    font-size: 16px;
                    font-weight: 600;
                }
            }
        }

        .log-in-button {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            margin-top: 30px;

            .theme-button {
                background-color: #000;
                border: 1px solid transparent;
                padding: 13px 63px;
                border-radius: 5px;
                color: $orange;
                font-family: 'Roboto';
            }
        }

        .password-forget {
            color: rgba(0, 0, 0, .361);
            font-size: 1rem;
            margin: auto;
            flex: 1;
            position: relative;
            -webkit-transform: translateY(48px);
            transform: translateY(48px);
            z-index: 3;

            @media screen and (max-width :992px) {
                transform: translateY(0px);
                padding-top: 20px;
                padding-bottom: 30px;
            }
        }

    }

    .images-f {
        bottom: 4px;
        left: -70px;
        position: absolute;
        z-index: 0;

        img {
            max-width: 800px;
            width: 100%;
        }
    }
}