custom-button {
    padding: 6px 30px;
    display: block;
    align-items: center;
    justify-content: center;
    outline: 0;
    border: 0;
    box-shadow: none;
    text-decoration: none;
    cursor: pointer;
    // margin-bottom: 10px;
    // border-radius: 5px

}

.visible-button {
    @extend custom-button;
    background-color: $orange;
    color: $black;
    transition: 0.1s all ease-in-out;
    // width: 100%;
    // border: 1px solid $black
}


.not-visible-button {
    @extend custom-button;
    background-color: $gray;
    color: $black;
    // width: 100%;
    border: 1px solid $black
}

.visible-button-1 {
    @extend custom-button;
    background-color: $orange;
}

.not-visible-button-1 {
    @extend custom-button;
    background-color: $gray;
}

.theme-black {
    @extend custom-button;
    background-color: $black;
    color: $orange;

    &:hover {
        color: $orange;
    }
}

.theme-button {
    appearance: none;
    -webkit-appearance: none;
    border: 1px solid white;
    outline: 0;
    box-shadow: none;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    font-weight: 700;
    gap: 8px;
    text-transform: uppercase;
}